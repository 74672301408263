import X from '@modules/common/icons/x'
import React, { FormEvent, useEffect, useRef } from 'react'
import SearchBoxWrapper, { ControlledSearchBoxProps } from '../search-box-wrapper'
import { useStore } from '@lib/context/store-context'
import Search from '@modules/common/icons/search'
import { useRouter } from 'next/navigation'

const ControlledSearchBox = ({
    inputRef,
    // isSearchStalled,
    onChange,
    onReset,
    onSubmit,
    placeholder,
    value,
    ...props
}: ControlledSearchBoxProps) => {
    const { searchIsOpen, resetSearch, setResetSearch } = useStore()
    const formRef = useRef<HTMLFormElement | null>(null)
    const router = useRouter()

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault()
        event.stopPropagation()

        if (onSubmit) {
            onReset && onReset()
            onSubmit(event)
        }

        if (inputRef.current) {
            onReset && onReset()
            inputRef.current.blur()
            router.push(`/search?q=${inputRef.current.value}`)
        }
        if (formRef.current) {
            formRef.current?.reset()
        }
    }

    const handleReset = (event: FormEvent) => {
        event.preventDefault()
        event.stopPropagation()

        onReset(event)

        if (inputRef.current) {
            inputRef.current.blur()
        }
    }

    useEffect(() => {
        if (resetSearch) {
            if (!searchIsOpen && formRef.current) {
                formRef.current?.reset()
            } else {
                inputRef.current?.blur()
            }
            setResetSearch(false)
        }
    }, [resetSearch])

    useEffect(() => {
        if (!searchIsOpen && formRef.current) {
            formRef.current?.reset()
        } else {
            inputRef.current?.blur()
        }
    }, [searchIsOpen])

    return (
        <div {...props} className="w-full relative">
            <form ref={formRef} noValidate onSubmit={handleSubmit}>
                <div className="flex items-center justify-between">
                    <input
                        autoFocus={false}
                        ref={inputRef}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="none"
                        placeholder={placeholder}
                        spellCheck={false}
                        type="search"
                        value={value}
                        onChange={onChange}
                        className="text-base-regular w-full py-1.5 lg:py-[9px] rounded pl-2 placeholder:transition-colors placeholder:text-black/30 focus:placeholder:text-gray-900 focus:outline-none bg-white border border-black/[.2]"
                    />
                    <button
                        aria-label={'search icon'}
                        type="button"
                        onClick={handleSubmit}
                        className="hidden lg:block lg:absolute lg:right-0 lg:top-1/2 lg:transform lg:-translate-y-1/2 lg:mr-2 lg:p-[1px] "
                    >
                        <Search />
                    </button>
                    <button
                        type="button"
                        onClick={handleReset}
                        className="lg:hidden absolute right-0 top-1/2 transform  -translate-y-1/2 mr-2 bg-weekly-red lg:bg-transparent p-[1px] [&>svg]:text-white hover:bg-weekly-red lg:p-2 [&>svg]:hover:text-white rounded transition-all duration-200"
                    >
                        <X size={14} />
                    </button>
                </div>
            </form>
        </div>
    )
}

const SearchBox = ({ onSubmit, handleReset }: any) => {
    return (
        <SearchBoxWrapper>
            {props => {
                return (
                    <>
                        <ControlledSearchBox {...props} onSubmit={onSubmit} onReset={handleReset} />
                    </>
                )
            }}
        </SearchBoxWrapper>
    )
}

export default SearchBox
