'use client'

import { useMobileMenu } from '@lib/context/mobile-menu-context'

import X from '@modules/common/icons/x'
import Link from 'next/link'
import WeeklyLogo from '@modules/common/icons/weekly-logo'
import React from 'react'
import User from '@modules/common/icons/user'
import Accordion from '@modules/common/components/accordion'

const MainMenu = () => {
    const {
        close,
        screen: [_, setScreen],
    } = useMobileMenu()

    return (
        <div className="flex flex-col flex-1 content-container">
            <div className="flex items-center justify-between w-full border-b border-gray-200 h-[63px]">
                <Link href="/" onClick={close} className={'h-full flex justify-center items-center'}>
                    <WeeklyLogo />
                </Link>

                <Link href="/account" onClick={close} className="ml-auto mr-6 border border-black/50 p-1 rounded">
                    <User size={24} />
                </Link>

                <button onClick={close} className="flex justify-end">
                    <X size={20} />
                </button>
            </div>

            <div className="flex flex-col items-start justify-between w-full border-b border-gray-200 px-4 py-2 gap-y-2">
                <Link href="/blog/tag/how-it-works/" className="text-lg">
                    How It Works
                </Link>
                <Link href="/blog/tag/about-us/" className="text-lg">
                    About Us
                </Link>
                <Link href="https://intercom.help/weeklyshop" aria-label="Go to FAQs" className="small-caps text-lg">
                    FAQs
                </Link>
                <Link href="/blog/" className="text-lg">
                    Blog
                </Link>
            </div>

            <div className="mt-4">
                <Accordion />
            </div>
        </div>
    )
}

export default MainMenu
