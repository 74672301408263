'use client'

import { useMobileMenu } from '@lib/context/mobile-menu-context'
import Hamburger from '@modules/common/components/hamburger'
import CartDropdown from '@modules/layout/components/cart-dropdown'
import MobileMenu from '@modules/mobile-menu/templates'
import clsx from 'clsx'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import WeeklyLogo from '@modules/common/icons/weekly-logo'
import Search from '@modules/common/icons/search'
import SearchBox from '@modules/search/components/search-box'
import classNames from 'classnames'
import User from '@modules/common/icons/user'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { scrollLock } from '@lib/util/scrollLock'
import { useStore } from '@lib/context/store-context'
import Overlay from '@modules/layout/components/overlay'
import CategoryDropdown from '@modules/common/components/category-dropdown'
import { LOGIN_VIEW, useAccount } from '@lib/context/account-context'
import { useRouter } from 'next/navigation'

const Nav = () => {
    const { searchIsOpen, handleSearch } = useStore()
    const { toggle } = useMobileMenu()
    const { loginView } = useAccount()
    const [_, setCurrentView] = loginView
    const [searchOpen, setSearchOpen] = useState<boolean>(false)

    useEffect(() => {
        if (searchIsOpen) {
            scrollLock.enable()
        } else {
            scrollLock.disable()
        }
    }, [searchIsOpen])
    const router = useRouter()
    return (
        <>
            <div className="sticky top-0 inset-x-0 z-50 group border-b lg:border-none bg-white">
                <header className="relative z-[999] h-16 px-4 lg:px-8 mx-auto transition-colors duration-200 max-w-[1440px]">
                    <nav className="flex text-gray-900 items-center justify-between w-full h-full text-small-regular transition-colors duration-200">
                        <Link href="/" aria-label={'Go to weekly shop homepage'}>
                            <WeeklyLogo />
                        </Link>
                        <div className="flex items-center h-full w-full justify-end">
                            <div className="flex items-center h-full">
                                <div className="flex items-center h-full w-full px-0 gap-x-4">
                                    <Link href="/blog/tag/how-it-works/" className="hidden sm:block text-lg">
                                        How It Works
                                    </Link>
                                    <Link href="/blog/tag/about-us/" className="hidden sm:block text-lg">
                                        About Us
                                    </Link>
                                    <Link href="/blog/" className="hidden sm:block text-lg">
                                        Blog
                                    </Link>
                                </div>
                                <div className="block lg:hidden ">
                                    <button
                                        onClick={() => setSearchOpen(!searchOpen)}
                                        className={classNames(' flex justify-center items-center')}
                                    >
                                        <Search size={20} height={20} />
                                    </button>
                                </div>
                                <div className="hidden lg:block">
                                    <div className={classNames('h-[63px] w-60 flex items-center px-2')}>
                                        <SearchBox className="flex w-full" />
                                    </div>
                                </div>
                                <div
                                    onClick={() => {
                                        setCurrentView(LOGIN_VIEW.SIGN_IN)
                                        router.push('/account')
                                    }}
                                    className={classNames(
                                        'cursor-pointer hidden h-full w-10 lg:flex justify-center items-center ',
                                    )}
                                >
                                    <User size={24} />
                                </div>

                                <div className={classNames('h-full w-10 flex justify-center items-center')}>
                                    <CartDropdown />
                                </div>
                                <div className="flex lg:hidden items-center justify-end h-[63px] pl-[11px]">
                                    <Hamburger setOpen={toggle} />
                                </div>
                            </div>
                        </div>
                    </nav>
                    <MobileMenu />
                </header>
                <div
                    className={classNames('absolute duration-500 w-full left-0', {
                        'top-0 opacity-0 invisible': !searchOpen,
                        'top-16 opacity-100 visible': searchOpen,
                    })}
                >
                    <SearchBox handleReset={() => setSearchOpen(false)} />
                </div>
                <div className={'lg:h-11'}>
                    <CategoryDropdown />
                </div>
                <div className={'block lg:hidden'}>{searchIsOpen ? <Overlay /> : ''}</div>
            </div>
        </>
    )
}

export default Nav
