import React from 'react'
import { IconProps } from 'types/icon'

const Cart: React.FC<IconProps> = ({ size = '20', color = 'currentColor', ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props?.className}
    >
        <circle cx="10" cy="20" r="1" fill="currentColor" />
        <circle cx="18" cy="20" r="1" fill="currentColor" />
        <path
            d="M3 3H4.30575C5.28342 3 6.1178 3.70683 6.27853 4.6712L7.72147 13.3288C7.8822 14.2932 8.71658 15 9.69425 15H18.2099C19.229 15 20.0851 14.2337 20.1977 13.2209L20.7532 8.22086C20.8849 7.03613 19.9575 6 18.7655 6H7"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
        />
    </svg>
)

export default Cart
