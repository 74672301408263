'use client'
import { useCart } from 'medusa-react'
import Link from 'next/link'
import Cart from '@modules/common/icons/cart'
import { useSubscription } from '@lib/context/subscription-context'
import { isSameDay } from 'date-fns'

const CartDropdown = () => {
    const { totalItems } = useCart()
    const { cart } = useCart()

    return (
        <Link href={'/cart'} aria-label={'Go to cart'}>
            <div className={'relative'}>
                <Cart />
                {(cart?.items || totalItems > 0) && (
                    <div
                        className={
                            'flex justify-center items-center w-5 h-5 rounded-full bg-weekly-red absolute top-[-10px] right-[-10px]  text-small-regular text-white'
                        }
                    >
                        <span>
                            {cart?.items
                                ? cart?.items.filter(item =>
                                      isSameDay(new Date(cart.next_delivery_at), new Date(item.shipment_at)),
                                  ).length
                                : totalItems}
                        </span>
                    </div>
                )}
            </div>
        </Link>
    )
}

export default CartDropdown
