'use client'

import X from '@modules/common/icons/x'
import Link from 'next/link'
import WeeklyLogo from '@modules/common/icons/weekly-logo'
import React from 'react'
import User from '@modules/common/icons/user'

const FilterMenu = () => {

    return (
        <div className="flex flex-col flex-1 content-container">
            <div className="flex items-center justify-between w-full border-b border-gray-200 h-[63px]">
                <Link href="/" onClick={close} className={'h-full flex justify-center items-center'}>
                    <WeeklyLogo/>
                </Link>

                <Link href="/account" onClick={close} className="ml-auto mr-6 border border-black/50 p-1 rounded">
                    <User size={24}/>
                </Link>

                <button onClick={close} className="flex justify-end">
                    <X size={20}/>
                </button>
            </div>
            <div className={'flex flex-col items-start gap-y-2'}>
                <h1>HERE SHOULD BE SOME INFO</h1>
                {/*<span className="text-[20px] font-semibold font-roboto">Filter page by</span>*/}
                {/*<div className="block w-full h-px bg-black/[0.12]"></div>*/}
                {/*<span className="font-medium text-[16px] font-roboto">Type</span>*/}
                {/*{product_categories*/}
                {/*    ?.filter(productCategory => productCategory.name === 'Type')*/}
                {/*    .map(productCategory => (*/}
                {/*        <label htmlFor={productCategory.name} className={'flex flex-row-reverse gap-x-2'}>*/}
                {/*            {productCategory.name}*/}
                {/*            <input type="checkbox" name={productCategory.name} className="rounded-none" />*/}
                {/*        </label>*/}
                {/*    ))}*/}
            </div>
        </div>
    )
}

export default FilterMenu
