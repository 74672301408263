import { FC, SVGAttributes } from 'react'

const WeeklyLogo: FC<SVGAttributes<SVGElement>> = () => (
    <svg width="110" height="43" viewBox="0 0 110 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1539_72)">
            <path
                d="M39.6798 33.5384C39.6798 35.4019 38.1769 36.9125 36.3228 36.9125C34.4688 36.9125 32.9659 35.4019 32.9659 33.5384C32.9659 31.6748 34.4688 30.1642 36.3228 30.1642C38.1769 30.1642 39.6798 31.6748 39.6798 33.5384Z"
                fill="currentColor"
            />
            <path
                d="M44.1646 36.5573C43.3436 36.1831 42.6939 35.6749 42.2151 35.0332C41.7363 34.3914 41.4739 33.6809 41.4285 32.9018H44.6434C44.7041 33.3909 44.9435 33.7956 45.3618 34.1163C45.7797 34.4373 46.3001 34.5975 46.9238 34.5975C47.5314 34.5975 48.0067 34.4756 48.3489 34.2312C48.6908 33.9868 48.862 33.6736 48.862 33.2914C48.862 32.8787 48.6527 32.5695 48.2348 32.3632C47.8164 32.1568 47.1516 31.9316 46.2397 31.6872C45.2969 31.4579 44.5256 31.221 43.9252 30.9766C43.3246 30.7322 42.8079 30.3578 42.3745 29.8534C41.9416 29.3492 41.7248 28.6697 41.7248 27.8138C41.7248 27.1111 41.9261 26.4693 42.3291 25.8886C42.7317 25.3081 43.3095 24.8497 44.0621 24.5134C44.8146 24.1775 45.7 24.0092 46.7185 24.0092C48.2233 24.0092 49.424 24.3874 50.3212 25.1435C51.2181 25.8999 51.7122 26.9201 51.8034 28.2033H48.7479C48.7023 27.6992 48.493 27.298 48.1208 27C47.748 26.7022 47.2504 26.5532 46.6272 26.5532C46.0494 26.5532 45.6047 26.6603 45.2934 26.874C44.9816 27.0881 44.8259 27.3862 44.8259 27.7679C44.8259 28.1958 45.0387 28.5205 45.4643 28.742C45.8898 28.9637 46.551 29.189 47.4482 29.418C48.3601 29.6473 49.1127 29.8844 49.7055 30.1285C50.2984 30.3732 50.8115 30.7514 51.2447 31.2631C51.6778 31.7751 51.9022 32.4511 51.9175 33.2914C51.9175 34.0248 51.7157 34.6819 51.3131 35.2625C50.91 35.8432 50.3327 36.2977 49.5802 36.6261C48.8277 36.9544 47.9498 37.1189 46.9466 37.1189C45.9126 37.1189 44.9856 36.9317 44.1646 36.5573Z"
                fill="currentColor"
            />
            <path
                d="M63.4826 24.6621C64.2271 25.0826 64.8085 25.7014 65.2268 26.5185C65.6447 27.3361 65.854 28.3175 65.854 29.4637V36.9123H62.6617V29.8991C62.6617 28.8907 62.4107 28.1154 61.9091 27.5727C61.4075 27.0307 60.7234 26.7592 59.8569 26.7592C58.9752 26.7592 58.2796 27.0307 57.7707 27.5727C57.2611 28.1154 57.0067 28.8907 57.0067 29.8991V36.9123H53.8143V19.9522H57.0067V25.7967C57.4173 25.2466 57.9645 24.815 58.6486 24.5018C59.3327 24.1887 60.0925 24.0317 60.9287 24.0317C61.8863 24.0317 62.7374 24.2421 63.4826 24.6621Z"
                fill="currentColor"
            />
            <path
                d="M75.4675 33.8987C75.9764 33.6162 76.3832 33.1922 76.6873 32.6266C76.9913 32.0614 77.1432 31.374 77.1432 30.564C77.1432 29.3571 76.8276 28.4289 76.1969 27.7794C75.566 27.1301 74.7949 26.8053 73.8827 26.8053C72.9705 26.8053 72.2067 27.1301 71.591 27.7794C70.9754 28.4289 70.6678 29.3571 70.6678 30.564C70.6678 31.7711 70.9676 32.6994 71.5685 33.3487C72.1686 33.9982 72.9251 34.3225 73.8371 34.3225C74.4146 34.3225 74.9579 34.1814 75.4675 33.8987ZM70.5535 36.3052C69.5804 35.7629 68.8168 34.9952 68.2621 34.0018C67.7068 33.0085 67.4298 31.8629 67.4298 30.564C67.4298 29.2654 67.7148 28.1195 68.2846 27.1263C68.8549 26.1331 69.6338 25.3653 70.6219 24.8229C71.6101 24.2807 72.7121 24.0091 73.9283 24.0091C75.1443 24.0091 76.2463 24.2807 77.2345 24.8229C78.2226 25.3653 79.0017 26.1331 79.5718 27.1263C80.1418 28.1195 80.4268 29.2654 80.4268 30.564C80.4268 31.8629 80.134 33.0085 79.549 34.0018C78.9636 34.9952 78.173 35.7629 77.1776 36.3052C76.1817 36.8476 75.0684 37.1187 73.8371 37.1187C72.6206 37.1187 71.5261 36.8476 70.5535 36.3052Z"
                fill="currentColor"
            />
            <path
                d="M91.3776 28.5354C91.066 27.9778 90.6554 27.5537 90.1463 27.2635C89.637 26.9734 89.0862 26.8278 88.4931 26.8278C87.9153 26.8278 87.3721 26.977 86.863 27.2748C86.3534 27.5729 85.9428 28.0047 85.6317 28.5699C85.3198 29.135 85.1641 29.7999 85.1641 30.5636C85.1641 31.328 85.3198 31.9925 85.6317 32.5576C85.9428 33.1233 86.3534 33.5546 86.863 33.8524C87.3721 34.1505 87.9153 34.2994 88.4931 34.2994C89.0862 34.2994 89.637 34.147 90.1463 33.8411C90.6554 33.5357 91.066 33.1001 91.3776 32.5347C91.6892 31.9695 91.8451 31.2971 91.8451 30.518C91.8451 29.7541 91.6892 29.0934 91.3776 28.5354ZM86.863 24.5932C87.5847 24.2037 88.4096 24.0089 89.3369 24.0089C90.4161 24.0089 91.3927 24.2765 92.2668 24.8111C93.1409 25.346 93.8325 26.106 94.3419 27.0914C94.8512 28.077 95.1059 29.2194 95.1059 30.518C95.1059 31.8168 94.8512 32.9665 94.3419 33.9673C93.8325 34.9681 93.1409 35.7434 92.2668 36.2934C91.3927 36.8434 90.4161 37.1186 89.3369 37.1186C88.4096 37.1186 87.5924 36.9274 86.8858 36.5454C86.1789 36.1639 85.6048 35.6826 85.1641 35.1017V42.9628H81.9718V24.2153H85.1641V26.0488C85.5745 25.4682 86.1408 24.983 86.863 24.5932Z"
                fill="currentColor"
            />
            <path
                d="M51.2556 4.29562L47.5698 16.9647H44.1341L41.8363 8.11462L39.5383 16.9647H36.0802L32.3715 4.29562H35.6024L37.832 13.946L40.2436 4.29562H43.6111L45.9771 13.9231L48.2069 4.29562H51.2556Z"
                fill="currentColor"
            />
            <path
                d="M60.6087 9.4411C60.5936 8.61783 60.2978 7.9586 59.7214 7.46293C59.1448 6.96774 58.4395 6.71979 57.6054 6.71979C56.8167 6.71979 56.1528 6.95994 55.6147 7.44001C55.076 7.92031 54.7463 8.58734 54.6249 9.4411H60.6087ZM63.8168 11.5909H54.6021C54.6779 12.5056 54.9965 13.2223 55.5578 13.7404C56.1187 14.2587 56.8091 14.5178 57.6282 14.5178C58.8113 14.5178 59.6532 14.0073 60.1536 12.9859H63.5894C63.2251 14.2056 62.5274 15.2078 61.496 15.993C60.4645 16.7784 59.1982 17.1706 57.6964 17.1706C56.483 17.1706 55.3946 16.9002 54.4314 16.3589C53.4682 15.8178 52.7175 15.0518 52.179 14.0604C51.6405 13.0696 51.3715 11.9263 51.3715 10.6303C51.3715 9.31937 51.6365 8.16826 52.1677 7.17717C52.6985 6.18631 53.4418 5.42403 54.3975 4.89031C55.353 4.35683 56.4526 4.08997 57.6964 4.08997C58.8943 4.08997 59.9679 4.34926 60.9158 4.86738C61.8637 5.38597 62.5996 6.12155 63.1228 7.07411C63.6461 8.02738 63.9078 9.12106 63.9078 10.3558C63.9078 10.8132 63.8772 11.2247 63.8168 11.5909Z"
                fill="currentColor"
            />
            <path
                d="M74.6491 9.4411C74.634 8.61783 74.3382 7.9586 73.7618 7.46293C73.1852 6.96774 72.4799 6.71979 71.6458 6.71979C70.8571 6.71979 70.1932 6.95994 69.6552 7.44001C69.1164 7.92031 68.7867 8.58734 68.6654 9.4411H74.6491ZM77.8572 11.5909H68.6425C68.7183 12.5056 69.0369 13.2223 69.5982 13.7404C70.1591 14.2587 70.8496 14.5178 71.6686 14.5178C72.8517 14.5178 73.6936 14.0073 74.194 12.9859H77.6298C77.2655 14.2056 76.5678 15.2078 75.5364 15.993C74.5049 16.7784 73.2386 17.1706 71.7368 17.1706C70.5234 17.1706 69.435 16.9002 68.4718 16.3589C67.5086 15.8178 66.7579 15.0518 66.2194 14.0604C65.6809 13.0696 65.4119 11.9263 65.4119 10.6303C65.4119 9.31937 65.6769 8.16826 66.2081 7.17717C66.7389 6.18631 67.4822 5.42403 68.4379 4.89031C69.3934 4.35683 70.493 4.08997 71.7368 4.08997C72.9347 4.08997 74.0083 4.34926 74.9562 4.86738C75.9042 5.38597 76.64 6.12155 77.1632 7.07411C77.6865 8.02738 77.9482 9.12106 77.9482 10.3558C77.9482 10.8132 77.9176 11.2247 77.8572 11.5909Z"
                fill="currentColor"
            />
            <path
                d="M86.8822 16.9648L82.6051 11.5678V16.9648H79.4196V0.0420227H82.6051V9.66976L86.8368 4.2957H90.9778L85.4263 10.653L91.0232 16.9648H86.8822Z"
                fill="currentColor"
            />
            <path d="M92.1649 0.0422974H95.3502V16.9651H92.1649V0.0422974Z" fill="currentColor" />
            <path
                d="M109.996 4.29581L102.192 22.9563H98.802L101.532 16.6448L96.4812 4.29581H100.053L103.307 13.1459L106.606 4.29581H109.996Z"
                fill="currentColor"
            />
            <path
                d="M27.118 27.912C26.3064 30.5179 24.5608 35.8348 20.8645 36.8443C15.4409 37.7302 12.4061 28.882 11.125 23.8062C10.7402 22.2826 9.87085 18.5395 9.62463 17.702C9.22791 16.3517 8.30537 13.2891 5.44202 13.4727C4.87151 13.5094 2.99232 13.5181 3.10825 15.6355C3.20161 17.3409 4.22857 21.6503 4.61401 23.3137C5.02789 25.0997 5.6412 27.3274 6.46568 29.5783C8.7931 35.9314 11.7143 35.0559 12.3859 35.0484C12.5197 35.047 12.5896 35.2077 12.4953 35.3032C11.9871 35.818 10.7438 36.893 9.2044 36.9126C7.48394 36.9346 5.8345 35.0264 4.8875 33.2023C2.85028 29.2793 1.73372 23.4538 0.716641 19.7885C-0.293151 14.9462 -1.1435 11.5659 4.85646 10.7171C9.02967 10.1269 11.7 12.5632 12.6124 17.1754C13.3997 21.1549 14.2922 25.5679 15.7577 28.9321C17.1379 32.1004 18.7652 33.7973 20.147 33.7973C22.3449 33.7973 24.4945 27.1513 25.2995 24.4747C25.932 22.3717 27.249 18.3244 27.1194 15.6953C27.0258 13.7991 25.3152 13.6944 24.9564 13.6984C22.9702 13.7207 20.3025 14.8455 19.0159 15.1188C18.1789 15.2965 16.8992 15.2381 16.4679 14.2915C15.3245 11.7817 18.8868 11.2598 19.9603 11.0521C22.8121 10.5004 26.7262 10.1959 28.8055 11.6943C29.0858 11.8964 29.8423 12.613 30.1422 13.8308C30.6838 16.0323 28.1884 24.4749 27.118 27.912Z"
                fill="#F7410F"
            />
            <path
                d="M12.1437 6.95513C12.135 6.5299 12.1552 6.10373 12.2251 5.68418C12.8543 1.90962 15.1747 0.766547 15.5157 0.570598C16.7811 -0.156706 17.8226 -0.0196126 18.1495 0.0813164C18.6655 0.240865 19.6793 0.964859 20.0315 1.75385C20.3495 2.46509 19.4011 2.84398 18.7094 3.00873C17.2246 3.3621 15.1766 4.72949 14.9264 7.5919C14.9257 7.60207 14.908 7.7316 14.8906 7.85782C14.8751 7.9708 14.9856 8.05968 15.0915 8.01855C15.1644 7.99018 15.2396 7.95946 15.2937 7.93345C15.9009 7.64154 16.9264 7.41746 17.8624 7.2201C20.0165 6.76603 19.8331 7.95473 19.3848 8.34048C18.4249 9.16659 16.426 10.2624 14.7803 11.0672C13.3068 11.7877 12.1439 11.6068 10.8428 10.1797C9.45059 8.65249 8.49277 7.37515 8.45185 7.32008C7.92744 6.61594 8.02268 6.1087 8.3425 5.99122C8.76391 5.83616 9.73796 6.33537 10.2412 6.54385C11.0692 6.88729 11.9048 7.56803 11.9048 7.56803C12.0002 7.64532 12.1427 7.58032 12.147 7.45694C12.1505 7.3527 12.1477 7.15462 12.1437 6.95513Z"
                fill="#44A53B"
            />
        </g>
        <defs>
            <clipPath id="clip0_1539_72">
                <rect width="110" height="43" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default WeeklyLogo
