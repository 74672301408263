import { useStore } from '@lib/context/store-context'
import classNames from 'classnames'

const Overlay = () => {
    const { searchIsOpen, handleSearch } = useStore()
    return (
        <div
            className={classNames(
                'absolute inset-0 h-screen z-10 bg-black/50 opacity-0 transition-opacity duration-300',
                {
                    'opacity-100': searchIsOpen,
                    'opacity-0': !searchIsOpen,
                }
            )}
            onClick={handleSearch}
        ></div>
    )
}

export default Overlay
