import Container from '@modules/mobile-menu/components/container'
import MainMenu from '@modules/mobile-menu/components/main-menu'
import { useMobileMenu } from '@lib/context/mobile-menu-context'
import FilterMenu from '@modules/mobile-menu/components/filter-menu'

const MobileMenu = () => {
    const {
        screen: [currentScreen],
    } = useMobileMenu()

    return (
        <Container>
            <div className="flex flex-col flex-1">
                {(() => {
                    switch (currentScreen) {
                        case 'filter':
                            return <FilterMenu />
                        default:
                            return <MainMenu />
                    }
                })()}
            </div>
        </Container>
    )
}

export default MobileMenu
