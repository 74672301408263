import {useProductCategories} from 'medusa-react'
import {ProductCategory} from '@medusajs/medusa'
import Link from 'next/link'
import React from 'react'

const CategoryDropdown = () => {
    const {product_categories} = useProductCategories({
        limit: 250
    })

    return (
        <div className="hidden lg:flex relative bg-weekly-red w-full h-full justify-center">
            <ul className="flex justify-center items-center">
                {product_categories
                    ?.filter(
                        productCategory => !productCategory.parent_category && !productCategory.handle.startsWith('_') && !productCategory.name.startsWith('Types') && !productCategory.name.startsWith('Type')
                    )
                    .map((category, index) => {
                        return (
                            <li
                                key={category.name}
                                className="group/item h-full flex items-center justify-center hover:bg-white px-3 cursor-pointer py-2 'hover:bg-[#FEE8E2] transition-all duration-300 relative"
                            >
                                <Link
                                    href={`/${category.handle}`}
                                    className="text-[16px] font-poppins tracking-wide text-white group-hover/item:text-weekly-red font-medium"
                                >
                                    {category.name}
                                </Link>
                                {category.category_children.length !== 0 && (
                                    <ul className="group-hover/item:opacity-100 group-hover/item:visible opacity-0 invisible absolute whitespace-nowrap overflow-visible bg-white left-0 top-full gap-x-4 py-5 px-6 justify-center shadow-sm border-x transition-all duration-300 border-b rounded-b">
                                        {category.category_children.map(child => (
                                            <li key={child.name}>
                                                <Link href={`/${category.handle}/${child.handle}`}>{child.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        )
                    })}
            </ul>
        </div>
    )
}

export default CategoryDropdown