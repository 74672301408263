import React from 'react'
import classNames from 'classnames'
import * as Accordion from '@radix-ui/react-accordion'
import ChevronDown from '@modules/common/icons/chevron-down'
import Link from 'next/link'
import { useMobileMenu } from '@lib/context/mobile-menu-context'
import { useProductCategories } from 'medusa-react'

const NestedAccordion = () => {
    const { product_categories } = useProductCategories({
        limit: 250,
    })
    const { close } = useMobileMenu()

    return (
        <Accordion.Root type="multiple">
            {product_categories
                ?.filter(productCategory => !productCategory.parent_category && !productCategory.handle.startsWith('_'))
                .map((productCategory, index) => (
                    <AccordionItem key={productCategory.id} value={`item-${index}`} level={1}>
                        <AccordionTrigger chevron>{productCategory.name}</AccordionTrigger>
                        <AccordionContent>
                            <Link
                                className="cursor-pointer underline items-center justify-between outline-none font-normal font-poppins text-[16px] py-2 px-4 bg-gray-200 flex w-full"
                                href={`/${productCategory.handle}`}
                                onClick={close}
                            >
                                View All
                            </Link>
                            {productCategory.category_children.map((children, index2) => (
                                <AccordionItem key={children.id} value={`item-1-${index}-${index2}`} level={2}>
                                    <Link href={`/${productCategory.handle}/${children.handle}`} onClick={close}>
                                        <AccordionTrigger>{children.name}</AccordionTrigger>
                                    </Link>
                                </AccordionItem>
                            ))}
                        </AccordionContent>
                    </AccordionItem>
                ))}
        </Accordion.Root>
    )
}

type CustomAccordionItemProps = {
    children: React.ReactNode
    className?: string
    value: string
    level: number
} & React.HTMLAttributes<HTMLDivElement>

type CustomAccordionTriggerProps = {
    children: React.ReactNode
    className?: string
    chevron?: boolean
} & React.HTMLAttributes<HTMLButtonElement>

const AccordionItem = ({ children, level, className, value, ...props }: CustomAccordionItemProps) => (
    <Accordion.Item value={value} {...props} className={classNames('py-2', `bg-gray-${level * 100}`, className)}>
        {children}
    </Accordion.Item>
)

const AccordionTrigger = ({ children, className, chevron, ...props }: CustomAccordionTriggerProps) => (
    <Accordion.Header className="flex px-4">
        <Accordion.Trigger
            className={classNames(
                'group flex flex-1 cursor-default items-center justify-between outline-none font-normal font-poppins text-[16px]',
                className,
            )}
            {...props}
        >
            {children}
            {chevron && (
                <ChevronDown
                    className="ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-180"
                    aria-hidden
                />
            )}
        </Accordion.Trigger>
    </Accordion.Header>
)

const AccordionContent = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <Accordion.Content
        className={classNames(
            'data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden bg-gray-300',
            className,
        )}
        {...props}
    >
        <div>{children}</div>
    </Accordion.Content>
)

export default NestedAccordion
